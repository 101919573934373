export const btnDis = require('../components/images/botones/figuras/btnDis.jpg'); //Botón deshabilitado


/*IMÁGENES DE LOS BOTONES DE LAS FIGURAS */
export const circUp = require('../components/images/botones/figuras/btnCirculoUp.jpg');
export const circOver = require('../components/images/botones/figuras/btnCirculoOver.jpg');
export const circDis = require('../components/images/botones/figuras/btnCirculoDis.jpg');
export const cuadUp = require('../components/images/botones/figuras/btnCuaUp.jpg');
export const cuadOver = require('../components/images/botones/figuras/btnCuaOver.jpg');
export const cuadDis = require('../components/images/botones/figuras/btnCuaDis.jpg');
export const triaUp = require('../components/images/botones/figuras/btnTrianUp.jpg');
export const triaOver = require('../components/images/botones/figuras/btnTrianOver.jpg');
export const triaDis = require('../components/images/botones/figuras/btnTrianDis.jpg');
export const romboUp = require('../components/images/botones/figuras/btnRomboUp.jpg');
export const romboOver = require('../components/images/botones/figuras/btnRomboOver.jpg');
export const romboDis = require('../components/images/botones/figuras/btnRomboDis.jpg');
export const hexaUp = require('../components/images/botones/figuras/btnHexaUp.jpg');
export const hexaOver = require('../components/images/botones/figuras/btnHexaOver.jpg');
export const hexaDis = require('../components/images/botones/figuras/btnHexaDis.jpg');
export const lineaUp = require('../components/images/botones/figuras/btnLineaUp.jpg');
export const lineaOver = require('../components/images/botones/figuras/btnLineaOver.jpg');
export const lineaDis = require('../components/images/botones/figuras/btnLineaDis.jpg');

/*IMÁGENES DE LOS BOTONES DE LAS VARIANTES DE LAS FIGURAS */
/*Variantes de los círculos*/
export const cir1Up = require('../components/images/botones/variantes/circulo/btnCirc1Up.jpg'); 
export const cir1Over = require('../components/images/botones/variantes/circulo/btnCirc1Over.jpg'); 
export const cir1Dis = require('../components/images/botones/variantes/circulo/btnCirc1Dis.jpg'); 
export const cir2Up = require('../components/images/botones/variantes/circulo/btnCirc2Up.jpg'); 
export const cir2Over = require('../components/images/botones/variantes/circulo/btnCirc2Over.jpg'); 
export const cir2Dis = require('../components/images/botones/variantes/circulo/btnCirc2Dis.jpg'); 
export const cir3Up = require('../components/images/botones/variantes/circulo/btnCirc3Up.jpg'); 
export const cir3Over = require('../components/images/botones/variantes/circulo/btnCirc3Over.jpg'); 
export const cir3Dis = require('../components/images/botones/variantes/circulo/btnCirc3Dis.jpg');
export const cir4Up = require('../components/images/botones/variantes/circulo/btnCirc4Up.jpg'); 
export const cir4Over = require('../components/images/botones/variantes/circulo/btnCirc4Over.jpg'); 
export const cir4Dis = require('../components/images/botones/variantes/circulo/btnCirc4Dis.jpg');
export const cir5Up = require('../components/images/botones/variantes/circulo/btnCirc5Up.jpg'); 
export const cir5Over = require('../components/images/botones/variantes/circulo/btnCirc5Over.jpg'); 
export const cir5Dis = require('../components/images/botones/variantes/circulo/btnCirc5Dis.jpg'); 
export const cir6Up = require('../components/images/botones/variantes/circulo/btnCirc6Up.jpg'); 
export const cir6Over = require('../components/images/botones/variantes/circulo/btnCirc6Over.jpg'); 
export const cir6Dis = require('../components/images/botones/variantes/circulo/btnCirc6Dis.jpg'); 
export const cir7Up = require('../components/images/botones/variantes/circulo/btnCirc7Up.jpg'); 
export const cir7Over = require('../components/images/botones/variantes/circulo/btnCirc7Over.jpg'); 
export const cir7Dis = require('../components/images/botones/variantes/circulo/btnCirc7Dis.jpg'); 
export const cir8Up = require('../components/images/botones/variantes/circulo/btnCirc8Up.jpg'); 
export const cir8Over = require('../components/images/botones/variantes/circulo/btnCirc8Over.jpg'); 
export const cir8Dis = require('../components/images/botones/variantes/circulo/btnCirc8Dis.jpg'); 
export const cir9Up = require('../components/images/botones/variantes/circulo/btnCirc9Up.jpg'); 
export const cir9Over = require('../components/images/botones/variantes/circulo/btnCirc9Over.jpg'); 
export const cir9Dis = require('../components/images/botones/variantes/circulo/btnCirc9Dis.jpg'); 
export const cir10Up = require('../components/images/botones/variantes/circulo/btnCirc10Up.jpg'); 
export const cir10Over = require('../components/images/botones/variantes/circulo/btnCirc10Over.jpg'); 
export const cir10Dis = require('../components/images/botones/variantes/circulo/btnCirc10Dis.jpg');
/* Variantes de los cuadrados */
export const cua1Up = require('../components/images/botones/variantes/cuadro/btnCuad1Up.jpg'); 
export const cua1Over = require('../components/images/botones/variantes/cuadro/btnCuad1Over.jpg'); 
export const cua1Dis = require('../components/images/botones/variantes/cuadro/btnCuad1Dis.jpg'); 
export const cua2Up = require('../components/images/botones/variantes/cuadro/btnCuad2Up.jpg'); 
export const cua2Over = require('../components/images/botones/variantes/cuadro/btnCuad2Over.jpg'); 
export const cua2Dis = require('../components/images/botones/variantes/cuadro/btnCuad2Dis.jpg'); 
export const cua3Up = require('../components/images/botones/variantes/cuadro/btnCuad3Up.jpg'); 
export const cua3Over = require('../components/images/botones/variantes/cuadro/btnCuad3Over.jpg'); 
export const cua3Dis = require('../components/images/botones/variantes/cuadro/btnCuad3Dis.jpg'); 
export const cua4Up = require('../components/images/botones/variantes/cuadro/btnCuad4Up.jpg'); 
export const cua4Over = require('../components/images/botones/variantes/cuadro/btnCuad4Over.jpg'); 
export const cua4Dis = require('../components/images/botones/variantes/cuadro/btnCuad4Dis.jpg'); 
export const cua5Up = require('../components/images/botones/variantes/cuadro/btnCuad5Up.jpg'); 
export const cua5Over = require('../components/images/botones/variantes/cuadro/btnCuad5Over.jpg'); 
export const cua5Dis = require('../components/images/botones/variantes/cuadro/btnCuad5Dis.jpg'); 
export const cua6Up = require('../components/images/botones/variantes/cuadro/btnCuad6Up.jpg'); 
export const cua6Over = require('../components/images/botones/variantes/cuadro/btnCuad6Over.jpg'); 
export const cua6Dis = require('../components/images/botones/variantes/cuadro/btnCuad6Dis.jpg'); 
export const cua7Up = require('../components/images/botones/variantes/cuadro/btnCuad7Up.jpg'); 
export const cua7Over = require('../components/images/botones/variantes/cuadro/btnCuad7Over.jpg'); 
export const cua7Dis = require('../components/images/botones/variantes/cuadro/btnCuad7Dis.jpg'); 
export const cua8Up = require('../components/images/botones/variantes/cuadro/btnCuad8Up.jpg'); 
export const cua8Over = require('../components/images/botones/variantes/cuadro/btnCuad8Over.jpg'); 
export const cua8Dis = require('../components/images/botones/variantes/cuadro/btnCuad8Dis.jpg'); 
export const cua9Up = require('../components/images/botones/variantes/cuadro/btnCuad9Up.jpg'); 
export const cua9Over = require('../components/images/botones/variantes/cuadro/btnCuad9Over.jpg'); 
export const cua9Dis = require('../components/images/botones/variantes/cuadro/btnCuad9Dis.jpg'); 
export const cua10Up = require('../components/images/botones/variantes/cuadro/btnCuad10Up.jpg');
export const cua10Over = require('../components/images/botones/variantes/cuadro/btnCuad10Over.jpg'); 
export const cua10Dis = require('../components/images/botones/variantes/cuadro/btnCuad10Dis.jpg');
/* Variantes de los triángulos */
export const tri1Up = require('../components/images/botones/variantes/triangulo/btnTrian1Up.jpg'); 
export const tri1Over = require('../components/images/botones/variantes/triangulo/btnTrian1Over.jpg'); 
export const tri1Dis = require('../components/images/botones/variantes/triangulo/btnTrian1Dis.jpg'); 
export const tri2Up = require('../components/images/botones/variantes/triangulo/btnTrian2Up.jpg'); 
export const tri2Over = require('../components/images/botones/variantes/triangulo/btnTrian2Over.jpg'); 
export const tri2Dis = require('../components/images/botones/variantes/triangulo/btnTrian2Dis.jpg'); 
export const tri3Up = require('../components/images/botones/variantes/triangulo/btnTrian3Up.jpg'); 
export const tri3Over = require('../components/images/botones/variantes/triangulo/btnTrian3Over.jpg'); 
export const tri3Dis = require('../components/images/botones/variantes/triangulo/btnTrian3Dis.jpg'); 
export const tri4Up = require('../components/images/botones/variantes/triangulo/btnTrian4Up.jpg'); 
export const tri4Over = require('../components/images/botones/variantes/triangulo/btnTrian4Over.jpg'); 
export const tri4Dis = require('../components/images/botones/variantes/triangulo/btnTrian4Dis.jpg'); 
export const tri5Up = require('../components/images/botones/variantes/triangulo/btnTrian5Up.jpg'); 
export const tri5Over = require('../components/images/botones/variantes/triangulo/btnTrian5Over.jpg'); 
export const tri5Dis = require('../components/images/botones/variantes/triangulo/btnTrian5Dis.jpg'); 
export const tri6Up = require('../components/images/botones/variantes/triangulo/btnTrian6Up.jpg'); 
export const tri6Over = require('../components/images/botones/variantes/triangulo/btnTrian6Over.jpg'); 
export const tri6Dis = require('../components/images/botones/variantes/triangulo/btnTrian6Dis.jpg'); 
export const tri7Up = require('../components/images/botones/variantes/triangulo/btnTrian7Up.jpg'); 
export const tri7Over = require('../components/images/botones/variantes/triangulo/btnTrian7Over.jpg'); 
export const tri7Dis = require('../components/images/botones/variantes/triangulo/btnTrian7Dis.jpg'); 
export const tri8Up = require('../components/images/botones/variantes/triangulo/btnTrian8Up.jpg'); 
export const tri8Over = require('../components/images/botones/variantes/triangulo/btnTrian8Over.jpg'); 
export const tri8Dis = require('../components/images/botones/variantes/triangulo/btnTrian8Dis.jpg'); 
export const tri9Up = require('../components/images/botones/variantes/triangulo/btnTrian9Up.jpg'); 
export const tri9Over = require('../components/images/botones/variantes/triangulo/btnTrian9Over.jpg'); 
export const tri9Dis = require('../components/images/botones/variantes/triangulo/btnTrian9Dis.jpg'); 
export const tri10Up = require('../components/images/botones/variantes/triangulo/btnTrian10Up.jpg'); 
export const tri10Over = require('../components/images/botones/variantes/triangulo/btnTrian10Over.jpg'); 
export const tri10Dis = require('../components/images/botones/variantes/triangulo/btnTrian10Dis.jpg');
/* Variantes de los Rombos */
export const rom1Up = require('../components/images/botones/variantes/rombo/btnRombo1Up.jpg'); 
export const rom1Over = require('../components/images/botones/variantes/rombo/btnRombo1Over.jpg'); 
export const rom1Dis = require('../components/images/botones/variantes/rombo/btnRombo1Dis.jpg'); 
export const rom2Up = require('../components/images/botones/variantes/rombo/btnRombo2Up.jpg'); 
export const rom2Over = require('../components/images/botones/variantes/rombo/btnRombo2Over.jpg'); 
export const rom2Dis = require('../components/images/botones/variantes/rombo/btnRombo2Dis.jpg'); 
/* Variantes de los hexágonos */
export const hex1Up = require('../components/images/botones/variantes/hexagono/btnHexa1Up.jpg'); 
export const hex1Over = require('../components/images/botones/variantes/hexagono/btnHexa1Over.jpg'); 
export const hex1Dis = require('../components/images/botones/variantes/hexagono/btnHexa1Dis.jpg'); 
export const hex2Up = require('../components/images/botones/variantes/hexagono/btnHexa2Up.jpg'); 
export const hex2Over = require('../components/images/botones/variantes/hexagono/btnHexa2Over.jpg'); 
export const hex2Dis = require('../components/images/botones/variantes/hexagono/btnHexa2Dis.jpg');
/* Variantes de las líneas */
export const lin1Up = require('../components/images/botones/variantes/linea/btnLin1Up.jpg'); 
export const lin1Over = require('../components/images/botones/variantes/linea/btnLin1Over.jpg'); 
export const lin1Dis = require('../components/images/botones/variantes/linea/btnLin1Dis.jpg'); 
export const lin2Up = require('../components/images/botones/variantes/linea/btnLin2Up.jpg'); 
export const lin2Over = require('../components/images/botones/variantes/linea/btnLin2Over.jpg'); 
export const lin2Dis = require('../components/images/botones/variantes/linea/btnLin2Dis.jpg'); 
export const lin3Up = require('../components/images/botones/variantes/linea/btnLin3Up.jpg'); 
export const lin3Over = require('../components/images/botones/variantes/linea/btnLin3Over.jpg'); 
export const lin3Dis = require('../components/images/botones/variantes/linea/btnLin3Dis.jpg'); 
export const lin4Up = require('../components/images/botones/variantes/linea/btnLin4Up.jpg'); 
export const lin4Over = require('../components/images/botones/variantes/linea/btnLin4Over.jpg'); 
export const lin4Dis = require('../components/images/botones/variantes/linea/btnLin4Dis.jpg');

export const salaMosUp = require('../components/images/botones/salas/mosaicosUp.png');
export const salaMosOver = require('../components/images/botones/salas/mosaicosDown.png');
export const salaMosDown = require('../components/images/botones/salas/mosaicosOver.png');
export const salaMurUp = require('../components/images/botones/salas/muralesUp.png');
export const salaMurOver = require('../components/images/botones/salas/muralesDown.png');
export const salaMurDown = require('../components/images/botones/salas/muralesOver.png');
export const salaCollUp = require('../components/images/botones/salas/collagesUp.png');
export const salaCollOver = require('../components/images/botones/salas/collagesDown.png');
export const salaCollDown = require('../components/images/botones/salas/collagesOver.png');

export const horno1 = require('../components/images/horno/horno1.jpg'); 
export const horno2 = require('../components/images/horno/horno2.png'); 
export const horno3 = require('../components/images/horno/horno3.png');
export const huecosTrans =  require('../components/images/horno4.png'); 